import React from "react"
import { graphql } from "gatsby"
import { FluidObject } from "gatsby-image"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageCompat from "../components/image"
import isValid from "date-fns/isValid"
import format from "date-fns/format"

const EventHeader = styled.div`
  display: flex;
`
const ImageWrapper = styled.div`
  width: 200px;
  margin-left: auto;
`

const Divider = styled.hr`
  margin-top: 2rem;
  margin-bottom: 3rem;
`

interface EventProps {
  data: {
    markdownRemark: {
      html: string
      frontmatter: {
        title: string
        speakers: string[]
        organisers: string[]
        startDate: string
        // ticketOptions: { label: string; stripePriceId: string }[]
        endDate: string | null
        image: {
          childImageSharp: {
            fluid: FluidObject | FluidObject[]
          }
        } | null
      }
    }
  }
}
const Event: React.FC<EventProps> = props => {
  const {
    data: {
      markdownRemark: {
        html,
        frontmatter: {
          title,
          image,
          speakers,
          organisers,
          startDate,
          endDate,
          // ticketOptions,
        },
      },
    },
  } = props

  const start = new Date(startDate)

  const formattedStartDate = isValid(start)
    ? format(start, "do MMM yyyy")
    : null

  const end = endDate ? new Date(endDate) : null
  const formattedEndDate =
    end && isValid(end) ? format(end, "do MMM yyyy") : null

  const isMultipleDays = endDate && formattedEndDate !== formattedStartDate

  return (
    <Layout>
      <SEO title={title} />
      <EventHeader>
        <div>
          <h1>{title}</h1>
          <h2 id="date">Date</h2>
          <p>
            {formattedStartDate}
            {isMultipleDays && ` - ${formattedEndDate}`}
          </p>
          {organisers.length && (
            <>
              <h2 id="organisers">Organisers</h2>
              <p>{organisers.join(", ")}</p>
            </>
          )}
          {speakers.length && (
            <>
              <h2>Speakers</h2>
              <p>{speakers.join(", ")}</p>
            </>
          )}
        </div>
        {image && (
          <ImageWrapper>
            <ImageCompat image={image} alt="profile picture" />
          </ImageWrapper>
        )}
      </EventHeader>
      <Divider />
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}

export default Event

export const eventQuery = graphql`
  query Event($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        speakers
        startDate
        endDate
        organisers
        image {
          childImageSharp {
            fluid(maxWidth: 120, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
